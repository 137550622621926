import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { productsArray } from '../../../components/store/src/productsStore';
import ProductCard from '../../../components/store/ProductCard';
import { FormControl, Card, CardActions, CardActionArea, CardHeader, CardMedia, CardContent, Typography, Button, Grid, Box } from "@mui/material";
import { useLoaderData, useSearchParams, useNavigate, useOutletContext } from "react-router-dom";

function Store(props) {

    return (
        <>
            <Box sx={{ width: "75%", margin: "5% auto" }}>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                >
                    {props.products !== undefined && props.products.map(product =>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={4}
                            sx={{ minHeight: {xs: "70vh", xl: "64vh"} }}
                        >
                            <Card
                                sx={{height: "100%", border: "none", borderRadius: "1.5em 0 1.5em 0", boxShadow: 1, opacity: "0.95", "&:hover": { boxShadow: 3, opacity: "1" } }}
                            >
                                <CardMedia
                                    component="img"
                                    height="220"
                                    image={
                                        product.images.length > 0 && product.images[0]
                                    }
                                />
                                <Box p={1} sx={{ height: "100%" }}>
                                    <CardHeader
                                        title={product.name}
                                        titleTypographyProps={{ variant: 'h6' }}
                                        sx={{paddingY: "0"}}
                                    />
                                    <CardContent sx={{ height: "200px", paddingY: "0" }}>
                                        <Typography variant="body2">
                                            {product.description}
                                        </Typography>
                                    </CardContent>
                                    <CardContent sx={{margin: "0", paddingY: "0"}} >
                                        <Typography variant="price" color="secondary.main">
                                            {(product.product_price.unit_amount / 100).toLocaleString("en-US", { style: "currency", currency: "USD" })}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{ margin: "0", paddingY: "0" }}>
                                        <ProductCard product={product} />
                                    </CardActions>
                                </Box>
                            </Card>
                        </Grid>
                        // End Grid Item
                    )}
                    {/* End Mapping Through Flowers*/}
                </Grid>
            </Box>
        </>
    )
}

export default Store;