import React from "react";
import {Box,Grid,Typography} from "@mui/material";

export default function VideoDesc(props) {
    console.log("Props",props);
    return (
<>

                {props.text !== undefined &&

                <Box 
                py={3}
                px={{xs: 0, md: 1}}
                sx={{
                        minHeight: "120px",
                        background: {xs: "none", md: "linear-gradient(to bottom, rgba(249,248,244,0.8) 0%, rgba(228,227,227,0.2) 100%)"},
                    }}
                    id="GradientBox"
                >
                <Typography 
                    
                    variant="pullquote" 
                    textAlign={"left"}
                    dangerouslySetInnerHTML={{__html:props.text}}
                >
                
                </Typography>
                </Box>

            }
    </>
    )
}