import React from "react";
import Title from "../../components/Utilities/Title";
import { Grid, Box, Button, Typography} from "@mui/material";
import { useLoaderData } from "react-router-dom";
import { Row } from "react-bootstrap";

export default function Resources() {
    const loaderData = useLoaderData();
    console.log("Resources Loader Data: ", loaderData)
    const color = [ '#267976', '#874973'];
    const background = [ '../static/images/buttonBackgrounds/buttonFilledGreen.png','../static/images/buttonBackgrounds/buttonFilledPurple.png',];
    const dynamicButtons = ['filledGreen', 'filledPurple',]

    return (
        <>
            
{/* The point of this box is solely to keep the margins on left and right consistent throughout */}
<Box sx={{ width: {xs: "85%", md: "75%"}, margin: "7.5% auto" }}>

<Title text={loaderData.loader1.title} />

<Grid
    container
    direction="row"
    justifyContent="space-evenly"
    alignItems="center"
    spacing={2}
>
    {/* Featured Resource */}
    <Grid item alignContent={"center"} textAlign={"center"} xs={12}>
    {loaderData.loader1 !== "" && loaderData.loader1.resource.filter(res => res.featured).map(res =>
    <>
    <Box
    name="featuredResourceBox"
    p={{xs: 2, md: 3}}
    sx={{
        borderRadius: '0 0 1.75em 0',
        boxShadow: 3,
        backgroundColor: 'background.default',
    }}>
        <Grid container direction={{xs: "column-reverse", lg: "row"}} spacing={1}>
            {/* Text of Featured Resource */}
            <Grid item xs={12} lg={8}>
                <Grid container direction={"column"} sx={{height: "100%"}} textAlign={{xs: "center", lg: "left"}} justifyContent={"space-between"} alignContent={"stretch"}>
                    <Grid item><Typography variant="h9" color="secondary.main">{res.title}</Typography></Grid>
                    <Grid item mt={1}><Typography variant="h5" color="secondary.main" sx={{fontSize: "1em", paddingTop: "0"}}>FEATURED RESOURCE</Typography></Grid>
                    <Grid item mt={1} flexGrow={5}><Typography variant="body1" dangerouslySetInnerHTML={{__html: res.description !== undefined && res.description !== null && res.description}}></Typography></Grid>
                    <Grid item xs="auto" mt={1} sx={{display: "flex", flexDirection: "column", justifyContent: "flex-end"}}><Box sx={{width: {xs: "220px", md: "50%"}, margin: {xs: "0 auto", lg: 0}, display: "flex" }}><Button variant="filledPurple" href={res.link}>Visit Site</Button></Box></Grid>
                </Grid>
            </Grid>
            {/* Image of Featured Resource */}
            <Grid item xs={12} lg={4} mb={{xs: 2, lg: 0}}>
                <Box
                sx={{
                height: "100%",
                minHeight: "280px",
                backgroundImage: `url( ${res.featured_image.meta.download_url} )`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                borderRadius: '1.5em 0 1.5em 0',
                minWidth: "100%",
                width: "auto",
                }}>
                </Box>
            </Grid>
        </Grid>
    </Box>
    </>
    )}
    </Grid>
    {/* End Featured Resource */}
    {/* Start Resources Grid */}
    <Grid item xs={12}>
        <Grid container
            direction="row"
            spacing={2}
            sx={{ height: "100%" }}
            alignItems="stretch"
            justifyContent="flex-start"
        >
{loaderData.loader1 !== "" && loaderData.loader1.resource.filter(res => !res.featured).map((res, i) =>
            <>

                        <Grid item xs={12} md={4}>
                            {/* Border Radius Sequence: 'Top-Left Top-Right Bottom-Right Bottom-Left'  */}
                                <Box 
                                name="resourceBox"
                                pb={2.75}
                                sx={{ 
                                border: '1px solid black', 
                                height: "100%",
                                minHeight: "370px",
                                borderRadius: '0 0 1.75em 0',
                                boxShadow: 3,
                                backgroundColor: 'background.default'}}>
                                    <Box name="imgBox"
                                        sx={{
                                            position: "relative",
                                        }}>
                                        <Box
                                            sx={{
                                                height: "77px",
                                                backgroundImage: `url( ${i % 2 ? background[0] : background[1]} )`,
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat",
                                                minWidth: "100%",
                                                width: "auto"
                                            }}
                                        />
                                        </Box>
                                    <Box name="title&DescBox"
                                        px={2.5} 
                                        py={2}
                                        sx={{
                                            borderRadius: '1.75em 0 0 0',
                                            backgroundColor: 'background.default',
                                            textAlign: 'center',
                                            marginTop: "-10%",
                                            position: "relative",
                                            zIndex: 90,
                                            height: "73%"
                                        }}>
                                        <Box sx={{height: "auto", margin: "0 auto 7.5%", fontSize: "1.6rem"}}>
                                        <Typography variant="h9" color={i % 2 ? color[0] : color[1]}>
                                            {res.title}
                                        </Typography>
                                        </Box>
                                        <Box sx={{height: "64%", padding: "0 0 2.5%", textTransform: 'none', fontSize: "1rem" }} >
                                        <Typography variant="body1" dangerouslySetInnerHTML={{__html: res.description }}>
                                        </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        name="resourceButtonBox"
                                        sx={{
                                            color: '#3f3f3f',
                                            height: "auto",
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                            width: {xs: "220px", md: "65%"},
                                            margin: "0 auto",
                                        }}>
                                            <Button variant={i % 2 ? dynamicButtons[0] : dynamicButtons[1]} href={res.link}>Visit Site</Button>
                                    </Box>

                                </Box>

                        </Grid>
                        </>
                    )}
        </Grid>
    </Grid>
</Grid>
</Box>


        </>
    )
}