import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from "axios";
import { Alert } from '@mui/material';
import { useLoaderData, useOutletContext, useNavigate } from "react-router-dom";
import CSRFToken from '../../loaders/CSRFToken';

export default function PasswordReset() {
    const navigate = useNavigate();
    const [alert, setAlert] = useState(undefined);
    const loaderData = useLoaderData();
    const [context] = useOutletContext();
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        axios.post(
            "/dj-rest-auth/password/reset/",
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': CSRFToken
                }
            }
        )
        .then(response => {
            console.log(response);
            response.data.type = "success";
            setAlert(response.data);
        })
        .catch(error => {
            console.log(error);
            error.response.data.type = "error";
            setAlert(error.response.data);
        })
        .finally(() => setTimeout(() => {setAlert(undefined);}, 5000))
    };

    return (
        <>
            <Typography component="h1" variant="h5">
                Password Reset
            </Typography>
            {alert !== undefined && alert.type !== "error" && Object.keys(alert).map(key => key !== "type" && <Alert severity={alert.type}>{alert[key]}</Alert>)}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    error={alert !== undefined && alert.type === "error"}
                    helperText={alert !== undefined && alert.email !== undefined && alert.email}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Send Email
                </Button>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Link href="login" variant="body2">
                            Already have an account?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="registration" variant="body2">
                            Don't have an account? Sign Up
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}