import React from "react";
import * as functions from "../../loaders/References"
import SubscriptionsLoader from "../../loaders/stripe/SubscriptionsLoader";
import stringSimilarity from "string-similarity";

export default function useFunction(props) {
    console.log("use comp", props);
    console.log(props);
    const Funct = functions[props];
    console.log("funct", Funct)
    return Funct !== undefined ? Funct() : () => {}
}