import React from "react";
import Title from "../../components/Utilities/Title";
import { Grid, Box, Typography, Divider } from "@mui/material";
import WhatOthersExperience from "../../components/ContentBlocks/WhatOtherExperience";
import TreatmentsAndBenefits from "./TreatmentsAndBenefits";
import { useLoaderData } from "react-router-dom";

export default function Details() {
    const loaderData = useLoaderData().loader1;
    console.log("service detail loader", loaderData);
    return (
        <>
            <Box sx={{width: {xs: "85%", md: "75%"}, margin: "7.5% auto"}}>
            <Title text={loaderData.title} />
            <Grid container
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-start"
                spacing={4}
                mt={3}
            >
                {loaderData.content_blocks !== undefined &&
                    loaderData.content_blocks.map(block =>
                        <Grid item md={block.width}>
                            {block.image !== null &&
                                <Box
                                    component="img"
                                    sx={{
                                        height: "auto",
                                        width: "100%",
                                    }}
                                    alt={block.image.title !== undefined && block.image.title}
                                    src={block.image.meta.download_url !== undefined && block.image.meta.download_url}
                                />
                                }
                                {block.body !== "" && block.body !== null && block.body !== undefined &&
                                <>
                                    <Divider />
                                    <br />
                                    <Typography variant="h5">
                                        {block.title !== undefined && block.title}
                                    </Typography>
                                    <br />
                                        <Typography variant="body1" dangerouslySetInnerHTML={{__html: block.body !== undefined && block.body}}>
                                        </Typography>
                                </>
}
                        </Grid>
                    )
                }

                <Grid item xs={12}>
                    <TreatmentsAndBenefits
                        benefits={loaderData.benefits}
                        treatments={loaderData.treatments}
                    />
                </Grid>

            </Grid>
            <WhatOthersExperience testimonies={loaderData.what_others_experience_quote !== undefined && loaderData.what_others_experience_quote}/>
            </Box>
            
        </>
    )
}