const userNavItems = [

    {
      "title": "UserMenu",
      "path": "",
      "order": 4,
      children: [
        {
          "title": "My Account",
          "path": "/authentication/my_account",
          "login_required": true,
          "order": 0
        },
        {
          "title": "Manage Membership",
          "path": "",
          "login_required": true,
          "subscriber_required": true,
          "order": 1
        },
        {
          "title": "Change Password",
          "path": "/authentication/password_change",
          "login_required": true,
          "order": 1
        },
        {
          "title": "Register",
          "path": "/authentication/registration",
          "new_user": true,
          "order": 2
        },
        {
          "title": "Login",
          "path": "/authentication/login",
          "new_user": true,
          "order": 3
        },
        {
          "title": "Logout",
          "login_required": true,
          "path": "",
          "order": 4
        }
      ]
    }
  ]

export default userNavItems;