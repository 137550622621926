import React from "react";
import Title from "../../components/Utilities/Title";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import UpdateIcon from '@mui/icons-material/Update';
import NonSubscriberIntro from "../../components/ContentBlocks/NonSubscriberIntro";
import { useLoaderData } from "react-router-dom";


export default function Videos() {
    const loaderData = useLoaderData();
    const pageData = loaderData.loader1;
    const videos = loaderData.loader2.find(c => c.data["items"] !== undefined).data.items;
    const featuredVideo = videos.find(v=>v.video.id===loaderData.loader1.featured_video.id);
    const subscriberContext = loaderData.loader2.find(c => c.data["subscriptions"] !== undefined);
    const isSubscriber = (subscriberContext !== undefined);
    console.log("Video Selection loaderdata", loaderData);
    console.log("subscriberContext", subscriberContext);
    console.log("featured_video", featuredVideo)

    return (

        <>
            {/* The point of this box is solely to keep the margins on left and right consistent throughout */}
            <Box sx={{ width: {xs: "85%", md: "75%"}, margin: "2.5% auto" }}>
            <Title text={pageData.title} />

            {/* Start Non-Subscriber Only content */}

                <NonSubscriberIntro />

            {/* End Non-Subscriber Content */}
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                columnSpacing={1}

            >
                {/* Featured Video */}
                <Grid item alignContent={"center"} textAlign={"center"} xs={12} mt={5}>
                    <Button href={featuredVideo !== undefined && featuredVideo.meta.html_url}>
                    <Box
                        component="img"

                        sx={{
                            height: "auto",
                            width: "100%",
                        }}
                        alt={featuredVideo !== undefined && featuredVideo.title}
                        src={featuredVideo !== undefined && featuredVideo.video.thumbnail}
                    />
                    </Button>
                </Grid>
                {/* End Featured Video */}
                <Grid item alignContent={"center"} textAlign={"center"} sx={{display: {xs: "none", md: "block"}}} xs={12}>
                    <Box my={3}>
                        <Divider />
                    </Box>
                </Grid>
                <Grid item alignContent={"center"} textAlign={"center"} xs={12} my={3} pl={0}>
                    <Typography variant="h3">
                        Select a video below
                    </Typography>
                </Grid>
                {/* Grid of Video Thumbnail Cards */}
                <Grid item alignContent={"center"} textAlign={"center"} xs={12}>
                    <Grid container
                        direction="row"
                        columnSpacing={{xs: 0, md: 3}}
                        rowSpacing={2}
                        sx={{margin: "0 auto", height: "100%", width: {xs: "100%", md: "auto"}}}
                        alignItems="stretch"
                    >
                        {isSubscriber !== undefined && videos !== undefined
                            && videos.filter(v =>
                                v.description !== null
                                && v.video !== null)
                                .map(videoPage =>
                                    <Grid item xs={12} md={videos.length > 2 ? 4:6} px={0}>
                                        {/* Border Radius Sequence: 'Top-Left Top-Right Bottom-Right Bottom-Left'  */}
                                        <Button
                                            href={videoPage.meta.html_url}
                                            sx={{ 
                                                height: "100%", 
                                                width: "100%",
                                                backgroundColor: 'background.default', 
                                                borderRadius: '1.75em 0 1.75em 0', 
                                                border: '1px solid black', 
                                                padding: "0",
                                                display: "flex",
                                                '&:hover': {
                                                    boxShadow: "5",
                                                    backgroundColor: 'background.default',
                                                }

                                             }}
                                        >
                                            <Box p={0} sx={{ 
                                            height: "100%",
                                            width: "100%",
                                            minHeight: {xs: "200px", md: "320px"},
                                            borderRadius: '1.75em 0 1.75em 0' }}>
                                                <Box id="imgBox"
                                                    sx={{
                                                        position: "relative",
                                                        height: "15%",
                                                        
                                                    }}>
                                                    <Box
                                                        component="img"
                                                        sx={{
                                                            height: "auto",
                                                            width: "100%",
                                                            borderRadius: '1.75em 0 0 0',
                                                        }}
                                                        alt={videoPage.meta.slug}
                                                        src={videoPage.video.thumbnail}
                                                    />
                                                </Box>
                                                <Box id="title&DescBox"
                                                    sx={{
                                                        borderRadius: '1.75em 0 1.75em 0',
                                                        backgroundColor: 'background.default',
                                                        textAlign: 'left',
                                                        marginTop: "20%",
                                                        position: "relative",
                                                        zIndex: 99,
                                                        height: "52%",
                                                        width: "100%",
                                                        padding: "7% 7% 0 7%",

                                                    }}>
                                                    <Typography variant="h6" sx={{ height: "37%", margin: 0, fontSize: "1.3rem", lineHeight: "1.05" }}>
                                                        {videoPage.title}
                                                    </Typography>

                                                    <Typography variant="body1" sx={{ height: "55%", margin: 0, padding: 0, textTransform: 'none', fontSize: "1rem" }} dangerouslySetInnerHTML={{__html: videoPage.description }}>
                                                    </Typography>


                                                </Box>

                                                <Box
                                                    id="durationBox"
                                                    sx={{
                                                        padding: "0 7%",
                                                        color: '#3f3f3f',
                                                        height: "11%",
                                                        display: 'flex',
                                                        margin: 0,
                                                        alignItems: 'center',
                                                        position: "relative",
                                                        flexWrap: 'wrap',
                                                        fontSize: '0.7rem',
                                                    }}>
                                                    <UpdateIcon mr={1} sx={{                        
                                                        height: "1rem",
                                                        width: "1rem",
                                                    }}></UpdateIcon><span sx={{
                                                        textTransform: 'capitalize',

                                                        fontSize: '0.7rem',

                                                    }}>&nbsp; &nbsp; Duration: {videoPage.preview_length.split(":")[1]} min.</span>
                                                </Box>

                                            </Box>

                                        </Button>
                                    </Grid>
                                )}
                    </Grid>
                </Grid>
            </Grid>
            </Box>

        </>
    )
}