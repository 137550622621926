import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { Box } from "@mui/material";
import Theme from "../../components/Utilities/Theme";
import Header from "../../components/Utilities/Header";
import Footer from "../../components/Utilities/Footer";
import { useLoaderData } from "react-router-dom";
import '@fontsource/italiana';
import '@fontsource-variable/open-sans';

export default function App() {
    const context = useLoaderData();
    console.log("home context", context);
    return (
        <ThemeProvider theme={Theme}>
            <Box sx={{height: "100vh"}}>
                <Header context={context} />
                <Box pt={5.5} mb={7} sx={{minHeight: "65vh"}}>
                    <Outlet context={[context]} />
                </Box>
                <Footer />
            </Box>
        </ThemeProvider>
    );
    }
