import React from "react";
import { Grid, Box, Typography, Divider, Button} from "@mui/material";
import { useLoaderData } from "react-router-dom";
import FullWidthBannerImg from '../../components/Images/FullWidthBannerImg.js';
import NonSubscriberIntro from '../../components/ContentBlocks/NonSubscriberIntro.js';
import ButtonOverImg from '../../components/Buttons/ButtonOverImg.js';

export default function Home() {
   const loaderData = useLoaderData();
   console.log("LOADERDATA!",loaderData);
   const videos = loaderData.loader2.find(c => c.data["items"] !== undefined).data.items;
   const featuredVideo = videos.find(v=>v.video.id===loaderData.loader1.featured_video.id);
 return (
    <>
    <FullWidthBannerImg img_url={loaderData.loader1.landing_image.meta.download_url} />
    <Box sx={{ width: {xs: "85%", md: "75%"}, margin: "7.5% auto" }}>

      <Grid 
         container 
         direction="row" 
         spacing={7} 
         justifyContent="space-evenly"
      >
         {/* Welcome Image */}
         <Grid item xs={12}>
        
        
         <Box
                component="img"
                src={loaderData.loader1.welcome_image.meta.download_url}
                sx={{  
                width: '100%',
                height: 'auto',
                maxHeight: '100%',
                padding: '0',
            }}
            
            ></Box>
         </Grid>
         {/* Who Are We */}
         <Grid item xs={12}>
         <Grid 
                container 
                direction="row" 
                spacing={5} 
                justifyContent="space-between"
                alignContent="stretch"
        >
                <Grid item xs={12} md={6}>
                <Box mt={{xs: 1, md: 7}} pr={{xs: 0, md: 5}}>
                    <Divider/>
                    <Typography variant="h5" mt={3} textAlign={{xs: "center", md: "left"}}>WHO ARE WE?</Typography>
                    
                    <Box mt={5} mb={3}>
                    <Typography variant="body1" mb={3} dangerouslySetInnerHTML={{__html: loaderData.loader1.who_are_we_text}}>
                    </Typography>
                    </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                        component="img"
                        src={loaderData.loader1.who_are_we_image.meta.download_url}
                        sx={{  
                        width: '100%',
                        height: 'auto',
                        padding: '0',
                        objectFit: 'cover',
                        }}>

                    </Box>
                </Grid>
        </Grid>
         </Grid>
         {/* What Do We Do */}
         <Grid item xs={12}>
         <Grid 
                container 
                direction="row" 
                spacing={5} 
                justifyContent="space-between"
                alignContent="stretch"
        >
                {loaderData.loader1.services !== null &&
                    <Grid item xs={12} md={6}>
                    <Box sx={{position: "relative"}} id="ButtonOverImgBox">
                    <Box
                        component="img"
                        src={loaderData.loader1.what_do_we_do_image.meta.download_url}
                        sx={{  
                        width: '100%',
                        height: 'auto',
                        padding: '0',
                        objectFit: 'cover',
                        }}>
                    </Box>
                    <ButtonOverImg text="View Services" href={loaderData.loader1.services.html_url}></ButtonOverImg>
                    </Box>
                </Grid>}
                <Grid item xs={12} md={loaderData.loader1.services !== null ? 6 : 12}>
                <Box mt={7} pr={{xs: 0, md: 5}}>
                    <Divider/>
                    <Typography variant="h5" mt={3} textAlign={{xs: "center", md: "left"}}>WHAT DO WE DO?</Typography>
                    <Box mt={5} mb={3}>
                    <Typography variant="body1" mb={3} dangerouslySetInnerHTML={{__html: loaderData.loader1.what_do_we_do_text}}>
                    </Typography>
                    </Box>
                    </Box>
                </Grid>
        </Grid>
         </Grid>
         {/* Non Subscriber Intro */}
         <Grid item xs={12}>
               <NonSubscriberIntro/>
         </Grid>
         {/* Featured Video */}
         <Grid item xs={12}>
            {/* Featured Video */}
            <Button href={featuredVideo !== undefined && featuredVideo.meta.html_url}>
                    <Box
                        component="img"

                        sx={{
                            height: "auto",
                            width: "100%",
                        }}
                        alt={featuredVideo !== undefined && featuredVideo.title}
                        src={featuredVideo !== undefined && featuredVideo.video.thumbnail}
                    />
                    </Button>
            {/* End Featured Video */}
         </Grid>
      </Grid>

      </Box>
  
    </>
 )   
}