import { Typography, Box, Divider, Grid, Icon } from "@mui/material";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import React from "react";
import Title from "../../components/Utilities/Title";
import FullWidthBannerImg from "../../components/Images/FullWidthBannerImg";
import WhatOthersExperience from "../../components/ContentBlocks/WhatOtherExperience";
import { useLoaderData } from "react-router-dom";

export default function AboutUs() {
    const loaderData = useLoaderData().loader1;
    console.log("about us user data", loaderData);
    return (
        <>
            <FullWidthBannerImg img_url={loaderData !== "" && loaderData.banner.meta.download_url}
            />
             <Box sx={{ width: {xs: "85%", md: "75%"}, margin: "7.5% auto" }}>
            <Title text="About Us" />
            <Grid container
                direction={"row"}
                justifyContent="center"
                alignItems="stretch"
                spacing={2}
                px={0}
            >
                {loaderData !== "" && loaderData.about_us_content_block.map(cb =>
                    <>
                        {cb.image !== "" && cb.image !== null &&
                            <Grid item xs={12} lg={cb.width} my={{xs: 1, md: 4}}>
                                {console.log(cb.image)}
                                <Box
                                    component="img"
                                    sx={{
                                        height: "auto",
                                        width: "100%",
                                        border: "none"
                                    }}
                                    alt={cb.image.meta.title}
                                    src={cb.image.meta.download_url}
                                />
                            </Grid>
                        }
                        
                        {cb.body !== "" && cb.body !== null && cb.title !== '' && 
                            <Grid item xs={12} lg={cb.width}  ml={{xs: 0, md: 3}} >
                                <Box>
                                <Divider sx={{ borderBottomWidth: 2, py: 2}} />
                                </Box>
                                <Box width="100%" mx={0} mt={4}>
                                    <Typography variant="h5" textAlign={{xs: "center", md: "left"}}>
                                    {cb.title !== undefined && cb.title !== null && cb.title}
                                    </Typography>
                                    <Typography pt={3} variant="body1" textAlign="left" dangerouslySetInnerHTML={{__html: cb.body !== undefined && cb.body !== null && cb.body}}>
                                    
                                    </Typography>
                                </Box>
                            </Grid>
                        }

                        {cb.body !== "" && cb.body !== null && cb.title == '' && 
                            <Grid item xs={12} lg={cb.width} ml={{xs: 0, md: 3}}>
                                <Box sx={{width: {xs: "100%", md: "80%"}}} mx={0} my={0} py={0}>
                                    <Typography variant="body1" textAlign="left" dangerouslySetInnerHTML={{__html: cb.body !== undefined && cb.body !== null && cb.body}} my={0}>
                                    </Typography>
                                </Box>
                            </Grid>
                        }
                    </>
                )}



            </Grid>
            </Box>
            <Box sx={{ width: { xs: "85%", md: "75%" }, margin: "7.5% auto" }}>
                <WhatOthersExperience testimonies={loaderData.what_others_experience_quote}/>
            </Box>
            
        </>
    )
}