import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLoaderData } from "react-router-dom";
import { Typography, Button, Alert, Box, TextField } from "@mui/material";
import axios from "axios";
import CSRFToken from "../../loaders/CSRFToken";

export default function ConfirmEmail(props) {
  const [alert, setAlert] = useState(undefined);
  const [freezeForm, setFreezeForm] = useState(false);
  console.log(props);
  const params = useParams();
  console.log("params", params);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    axios
      .post(
        alert !== undefined && alert.message !== "Email has been verified!"
            ? "/dj-rest-auth/resend-email/"
            : "/dj-rest-auth/verify-email/"
        ,
        data,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": CSRFToken,
          },
        },
      )
      .then((res) => {
        console.log("res", res);
        setFreezeForm(false);
        alert !== undefined && alert.message !== "Email has been verified!"
            ? setAlert({type: "success", message: "Email was sent succesfully. Click the link in your inbox to confirm your email."})
            : setAlert({type: "success", message: "Email has been verified!"})
      })
      .catch((error) => {
        console.log(error);
        error.response.data.type = "error";
        setAlert(error.response.data);
      });
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        Confirm Email
      </Typography>
      {alert !== undefined &&
        Object.keys(alert).map(
          (key) =>
            key !== "type" && (
              <Alert severity={alert.type}>
                {console.log(alert.type)}
                {alert[key]}
              </Alert>
            ),
        )}
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        {alert === undefined && (
          <>
            <TextField
              value={params.key !== undefined && params.key}
              required
              fullWidth
              id="key"
              name="key"
              label="Key"
              autoFocus
              disabled={freezeForm}
              aria-disabled
              sx={{ display: "none" }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={freezeForm}
              sx={{ mt: 3, mb: 2 }}
            >
              Confirm Email
            </Button>
          </>
        )}
        {alert !== undefined && alert.type !== "success" && (
          <>
            <TextField
              required
              fullWidth
              id="email"
              name="email"
              label="Email"
              type="email"
              autoFocus
              disabled={freezeForm}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={freezeForm}
              sx={{ mt: 3, mb: 2 }}
            >
              Resend Email
            </Button>
          </>
        )}
      </Box>
    </>
  );
}
