import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Theme from "../../components/Utilities/Theme";
import Copyright from '../../components/Utilities/Copyright';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Typography } from '@mui/material';

export default function Index() {
    const [context] = useOutletContext();
    return (
            <Container component="main" maxWidth="xs">
                {console.log(context)}
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Outlet context={[context]} />
                </Box>
            </Container>
    );
}