import React from "react";
import { Grid, Box, Typography, List, ListItem, Button } from "@mui/material";
import ControlPointIcon from '@mui/icons-material/ControlPoint';

export default function TreatmentsAndBenefits(props) {
    return (
        <>
            <Box
                sx={{
                    height: "auto",
                    width: "100%",
                    backgroundColor: "background.default",
                    padding: "6% 12%",
                    boxShadow: "3",
                    border: "none",
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="space-evenly"
                    spacing={{ xs: 3, md: 1 }}
                    textAlign={{ xs: "center", md: "left" }}
                >
                    {/* Treatments */}
                    <Grid item xs={12} md={5}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={1}

                        >
                            <Grid item xs={12}>
                                <Typography variant="h8" color="secondary.main">
                                    Treatments
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <List>
                                    {props.treatments !== undefined && props.treatments.map(treatments =>

                                        <>
                                            <ListItem sx={{ paddingLeft: "0", marginTop: "4px" }}>

                                                <ControlPointIcon color="secondary" />

                                                <Typography variant="body1" pl={1}>
                                                    {treatments.name}
                                                </Typography>
                                            </ListItem >
                                        </>
                                    )}
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Benefits */}
                    <Grid item xs={12} md={5}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={1}
                        >
                            <Grid item xs={12} ml={{ xs: 0, md: 5 }}>
                                <Typography variant="h8" color="secondary.main">
                                    Benefits
                                </Typography>
                            </Grid>
                            <Grid item xs={12} ml={{ xs: 0, md: 5 }}>
                                <List>
                                    {props.benefits !== undefined && props.benefits.map(benefits =>

                                        <>
                                            <ListItem sx={{ paddingLeft: "0", marginTop: "4px" }}>

                                                <ControlPointIcon color="secondary" />

                                                <Typography variant="body1" pl={1}>
                                                    {benefits.name}
                                                </Typography>
                                            </ListItem >
                                        </>
                                    )}
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Button Box! */}
                <Box sx={{ width: { xs: "100%", md: "35%" }, margin: "9% auto 1%" }}>
                    <Button href="/about/contact" variant="filledGreen">
                        Book Now
                    </Button>
                </Box>
            </Box>
        </>
    )
}