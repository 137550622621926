import React from "react";
import SubCancel from "../subscription/SubCancel";
import SubSuccess from "../subscription/SubSuccess";
import ProdCancel from "../products/ProdCancel";
import ProdSuccess from "../products/ProdSuccess";
import UserLoader from "../../loaders/UserLoader";

const StripeStatusPages = [
      {
        path: "subscriptions/cancel",
        element: <SubCancel />,
        loader: UserLoader
      },
      {
        path: "subscriptions/success",
        element: <SubSuccess />,
        loader: UserLoader
      },
      {
        path: "products/prodcancel",
        element: <ProdCancel />,
        loader: UserLoader
      },
      {
        path: "products/prodsuccess",
        element: <ProdSuccess />,
        loader: UserLoader
      },
    
    ];

export default StripeStatusPages;