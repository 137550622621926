import React from "react";
import axios from "axios";

export default function PageLoader() {
  let response = [];
  const res = axios.all([
    axios.get("/api/v2/pages/?fields=show_in_menus")
      .catch(error => console.log(error))
      .then(res => { 
        response.push(res);
        axios.get("/api/users/current/")
        .catch(error => console.log(error))
        .then(res => { response.push(res) });
        axios.get("/stripe/subscriptions/")
        .catch(error => console.log(error))
        .then(res => { response.push(res) });
       }),

  ]).then(() => { return response })
  return res;
}