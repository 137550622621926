import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Hidden from '@mui/material/Hidden';
import axios from "axios";
import { useLoaderData, useSearchParams, useNavigate, useOutletContext } from "react-router-dom";
import CSRFToken from '../../loaders/CSRFToken';

export default function Password() {
    const loaderData = useLoaderData();
    const [context] = useOutletContext();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [alert, setAlert] = useState(undefined);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            uid: data.get('uid'),
            token: data.get('token'),
            new_password1: data.get('new_password1'),
            new_password2: data.get('new_password2'),
        });
        axios.post(
            "/dj-rest-auth/password/change/",
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': CSRFToken
                }
            }
        )
            .then(response => {
                console.log(response);
                response.data.type = "success";
                setAlert(response.data);
            })
            .catch(error => {
                console.log(error);
                error.response.data.type = "error";
                setAlert(error.response.data);
            })
            .finally(() => setTimeout(() => { const navigate = useNavigate("/authentication/login/"); }, 5000))
    };

    useEffect(() => {
        context.id === null && navigate("/authentication/login")
    }, [])

    return (
        <>
                <Typography component="h1" variant="h5">
                    Change Password
                </Typography>
                {alert !== undefined && Object.keys(alert).map(key => key !== "type" && <Alert severity={alert.type}>{alert[key]}</Alert>)}
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Input
                        sx={{ "display": "none" }}
                        id="uid"
                        name="uid"
                        readOnly
                        required
                        value={"pasta"}
                    />
                    <Input
                        sx={{ "display": "none" }}
                        id="token"
                        name="token"
                        readOnly
                        required
                        value={"sauce"}
                    />
                    <TextField
                        error={alert !== undefined}
                        helperText={alert !== undefined && alert.new_password1 !== undefined && alert.new_password1}
                        margin="normal"
                        required
                        fullWidth
                        name="new_password1"
                        label="Password"
                        type="password"
                        id="new_password1"
                    />
                    <TextField
                        error={alert !== undefined}
                        helperText={alert !== undefined && alert.new_password2 !== undefined && alert.new_password2}
                        margin="normal"
                        required
                        fullWidth
                        name="new_password2"
                        label="Confirm Password"
                        type="password"
                        id="new_password2"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Change Password
                    </Button>
                </Box>
            </>
            );
}