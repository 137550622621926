import { Divider, Typography, Box, Grid, Link, Icon } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Instagram, Facebook, Email, Send } from "@mui/icons-material";

export default function Footer() {
  const [footerDetails, setFooterDetails] = useState(undefined);
  const [contactDetails, setContactDetails] = useState(undefined);

  const getFooterDetails = () => {
    axios
      .get("/api/v2/pages/?type=footer.Footer")
      .catch((error) => console.log("footer error", error))
      .then((response) => {
        console.log("footer response", response);
        axios
          .get(response.data.items[0].meta.detail_url)
          .catch((error) => console.log("footer details error", error))
          .then((res) => {
            console.log("footer details response", res);
            setFooterDetails(res.data);
          });
      });
  };

  const getContactDetails = () => {
    axios
      .get("/api/v2/pages/?type=contact.ContactUs")
      .catch((error) => console.log("footer error", error))
      .then((response) => {
        console.log("contact response", response);
        setContactDetails(response.data.items[0]);
      });
  };

  useEffect(() => {
    getFooterDetails();
    getContactDetails();
  }, []);

  return (
    <>
      {footerDetails !== undefined && (
        <>
          {/* This box adds the background image and size and structure */}
          <Box
            sx={{
              zIndex: 98,
              width: "100%", 
              minHeight: "510px",
              height: {xs: "775px", md: "30vh", lg: "64vh"},
              maxHeight: {xs: "775px", md: "600px"},
              marginBottom: "-20px",
              backgroundSize: "cover", 
              backgroundPosition: "top",
              backgroundImage: 'url(../../../static/images/Footer_BG.png)',
              overflowY: "hidden",
            }}
            pt={8}
            m={0}
            mt={5}
          >
            {/* The box below is for centering the content and aligning it with the rest of the website */}
            <Box sx={{ width: "75%", margin: "0 auto" }}>
              <Grid
                // Title Box
                container
                mt={2}
                px={0}
              >
                <Grid item xs={12} p={0}>
                  <Typography variant="h7">Winter Wisdoms</Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ margin: "2% 0", width: "100%" }} />
            <Box sx={{ width: "75%", margin: "0 auto" }}>
              <Grid
                // Content Box
                px={0}
                container
                direction="row"
                spacing={2}
              >
                <Grid item xs={12} md={8}>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12}>
                      <Typography variant="h6">CONTACT</Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{ fontSize: "1.1rem" }}>
                      <Button
                      variant="textLinks"
                      sx={{paddingLeft: "0"}}
                        href={`mailto:${
                          footerDetails.contact_email_address !== "" &&
                          footerDetails.contact_email_address
                        }`}
                      >
                        <Icon
                          sx={{display: "inline-block", marginRight: "6px", marginBottom: "2px"}}                        
                        >
                          <Email />
                        </Icon>
                        {footerDetails.contact_email_display_name !== "" &&
                          footerDetails.contact_email_display_name}
                      </Button>
                    </Grid>
                    {contactDetails !== undefined && (
                      <Grid item xs={12} lg={6} sx={{ fontSize: "1.1rem" }}>
                        <Button
                        variant="textLinks"
                        sx={{paddingLeft: "0"}}
                         href={contactDetails.meta.html_url}>
                         <Icon
                          sx={{display: "inline-block", marginRight: "6px", marginBottom: "2px"}}
                          >
                            <Send />
                          </Icon>
                          Contact Form
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12}>
                      <Typography variant="h6">SOCIAL</Typography>
                    </Grid>
                    {footerDetails.social_insta_display_name !== "" && (
                      <Grid item xs={12} lg={6} sx={{ fontSize: "1.1rem" }}>
                        <Button variant="textLinks" sx={{paddingLeft: "0"}} href={footerDetails.social_insta_link}>
                          <Icon
                          sx={{display: "inline-block", marginRight: "6px", marginBottom: "2px"}}
                          >
                            <Instagram />
                          </Icon>
                          {footerDetails.social_insta_display_name}
                        </Button>
                      </Grid>
                    )}
                    {footerDetails.social_fb_display_name !== "" && (
                      <Grid item xs={12} lg={6} sx={{ fontSize: "1.1rem" }}>
                        <Button variant="textLinks" sx={{paddingLeft: "0"}} href={footerDetails.social_fb_lin}>
                          <Icon
                          sx={{display: "inline-block", marginRight: "6px", marginBottom: "2px"}}
                          >
                            <Facebook />
                          </Icon>
                          {footerDetails.social_fb_display_name}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {/* // Copyright Box */}
            <Box
              sx={{ borderTop: "solid 1px rgba(0, 0, 0, 0.2)", width: "100%" }}
              mt={5}
              mb={2}
              mx={0}
              px={0}
              py={2}
            >
              {/* The box below centers the content of the Copyright box */}
              <Box sx={{ width: "75%", margin: "0 auto" }}>
                <Typography variant="caption">
                  &copy; Copyright{" "}
                  <script>document.write(new Date().getFullYear());</script>{" "}
                  Winter Wisdoms. All Rights Reserved.
                </Typography>
              </Box>
            </Box>
            {/* End Copyright Box */}
          </Box>
        </>
      )}
    </>
  );
}
