import React from "react";
import axios from "axios";

export default function BillingPortalLoader(price_id) {
        const res =         // Get Stripe publishable key
        axios.get("/stripe/config/",
        )
        .catch((error) => {
            console.log(error)
        })
        .then(response => {
            console.log(response);
            // Create Checkout Session
            axios.get(`/stripe/create-checkout-session/?priceId=${price_id}`,
            ).catch(error=>console.log(error))
            .then(res=> {return res})
        })
        return res;
}