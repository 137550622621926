import React from "react";
import { Box} from "@mui/material";


export default function FullWidthBannerImg(props) {
    return (
        <>
            <Box
                component="img"
                src={props.img_url}
                sx={{
                    height: {xs: '56vh', sm: '40vh', md: '40vh', lg: "44vh", xl: "60vh"},
                    width: '100%',
                    padding: '0',
                    objectFit: 'cover',
                    objectPosition: 'center center'
            }}
            
            ></Box>
        </>
    )
}