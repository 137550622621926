import React from "react";
import { Grid, Typography, Divider, Box } from "@mui/material";
import Quotemark from "../Images/Quotemark";

// import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

export default function WhatOthersExperience(props) {
    return (
        <>
                <Grid container
                    direction={"row"}
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={{ xs: 3, md: 2 }}
                    mt={{ xs: 6, md: 4 }}
                    mx={0}
                    px={0}
                >
                    <Grid item xs={12} md={12} mb={4}>
                        <Typography variant="h2" textAlign={"center"} color="primary.main">
                            What Others Experience
                        </Typography>
                    </Grid>
                    {props.testimonies !== undefined && props.testimonies.map((testimony, i) =>
                        <Grid item xs={12} lg={props.testimonies.length > 2 ? 4 : 5}>
                            {/* The point of the switching cases based on the index number, is to change the border radius of the box based on whether it's left, center, or right */}
                                <Box 
                                    id="cardBox1" 
                                    sx={{ 
                                        border: "none", 
                                        borderRadius: { 
                                            xs: '1.5em 0 1.5em 0', 
                                            md: i  === 0 ? '1.5em 0 0 1.5em' : i === 1 ? '0' : '0 1.5em 1.5em 0%' }, 
                                        height: "100%", boxShadow: 3 }} 
                                        py={3} 
                                        px={{ xs: 2, sm: 5, lg: 3.5 }}
                                    >
                                    <Grid item sx={{ height: "100%" }}>
                                        <Grid container direction="column" justifyContent={"flex-start"} alignItems={"center"} textAlign={"center"}>
                                            <Grid item mb={2} xs={8} md={3}>
                                                <Quotemark />
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Divider sx={{ borderBottomSize: 2, borderColor: "secondary.main" }} />
                                            </Grid>
                                            <Grid item mt={2} xs={12} md={5}>
                                                <Typography variant="body1" textAlign={"center"} color="secondary.main" dangerouslySetInnerHTML={{ __html: testimony.quote }}>
                                                </Typography>
                                            </Grid>
                                            <Grid item mt={2} xs={12} md={3} textAlign="bottom">
                                                <Typography variant={"h6"} sx={{ fontWeight: "600" }} color="secondary.main" textAlign="bottom">
                                                    {testimony.author}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                        </Grid>
                    )}
                </Grid>
        </>
    )
}