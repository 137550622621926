import React, { useState } from 'react';
import { Typography, Box, Divider, Grid, Container, Icon, TextField, Button, Alert } from "@mui/material";
import Title from "../../components/Utilities/Title";
import axios from 'axios';
import { useLoaderData, useNavigate, useOutletContext } from "react-router-dom";

export default function AboutUs() {
    const [alert, setAlert] = useState(undefined);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const loaderData = useLoaderData().loader1;
    const navigate = useNavigate();
    console.log("contact us loader data", loaderData);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        axios.post(
            "/api/contact/ci/",
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': loaderData.csrf_token
                }
            }
        )
            .then(response => {
                console.log(response);
                setAlert({ message: "Successfully sent message" });
                alert.type = "success";
            })
            .catch(error => {
                console.log(error);
                error.response.data.type = "error"
                setAlert(error.response.data);
            })
            .finally(() => setTimeout(() => {
                setAlert(undefined);
                setName("");
                setEmail("");
                setMessage("");
            }, 5000))
    };


    return (
        <>
            <Box sx={{ width: {xs: "85%", md: "75%"}, margin: "2.5% auto" }}>
                <Title text={loaderData.title} color="secondary" />
                <Typography variant="body1" color="secondary" dangerouslySetInnerHTML={{ __html: loaderData.description }} >
                </Typography>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    sx={{ 
                        borderStyle: {xs: "none", md: "solid"}, 
                        borderWidth: {xs: "0", md: "1px"}, 
                        borderColor: "secondary.main" }}

                >
                    <Grid item xs={12}>
                        <Box id="imgBox"
                            sx={{
                                position: "relative",
                            }}>
                            <Box
                                component="img"
                                sx={{
                                    height: "auto",
                                    width: "100%",
                                    borderRadius: '1.75em 0 0 0',
                                }}
                                alt={loaderData.banner.title}
                                src={loaderData.banner.meta.download_url}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {alert !== undefined && Object.keys(alert).map(key => key !== "type" && <Alert severity={alert.type}>{console.log(alert.type)}{alert[key]}</Alert>)}
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            {console.log(name)}
                            <TextField
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                error={alert !== undefined && alert.type === "error"}
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="name"
                                name="name"
                                autoComplete="name"
                                autoFocus
                            />
                            <TextField
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                error={alert !== undefined && alert.type === "error"}
                                margin="normal"
                                required
                                fullWidth
                                name="email"
                                label="email"
                                type="email"
                                id="email"
                                autoComplete="email"
                            />
                            <TextField
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                error={alert !== undefined && alert.type === "error"}
                                margin="normal"
                                required
                                fullWidth
                                name="message"
                                label="message"
                                type="message"
                                id="message"
                                autoComplete="message"
                                rows={4}
                                multiline
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="filledPurple"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <br />
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    sx={{ borderStyle: "solid", borderWidth: "1px", borderColor: "secondary.main" }}
                >
                    <Grid item xs={12}>
                        <Box sx={{ margin: 2 }}>
                            <Typography textAlign={"center"} variant='body1'>
                                You can also send us an email:
                                <Typography textAlign={"center"} variant='body1'>
                                    <Button href={`mailto:${loaderData.contact_email}`} sx={{ textTransform: 'none' }}>
                                    <Typography textAlign={"center"} variant='body1'>
                                        {loaderData.contact_email}
                                    </Typography>
                                    </Button>
                                </Typography>
                            </Typography >
                        </Box>
                    </Grid>

                </Grid>
            </Box>

        </>
    )
}