import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from "axios";
import { useLoaderData } from "react-router-dom";
import CSRFToken from '../../loaders/CSRFToken';

export default function Register () {
    const loaderData = useLoaderData();
    const [alert, setAlert] = useState(undefined);
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        axios.post(
            "/dj-rest-auth/registration/",
            data,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': CSRFToken
                }
            }
        )
        .then(response => {
            console.log(response);
            window.location.href = "/authentication/my_account"
        })
        .catch(error => {
            console.log(error);
            error.response.data.type = "error";
            setAlert(error.response.data);
        })
    };

    return (
        <>
            <Typography component="h1" variant="h5">
               Register
            </Typography>
            {alert !== undefined && alert.type !== "error" && Object.keys(alert).map(key => key !== "type" && <Alert severity={alert.type}>{alert[key]}</Alert>)}
            {alert !== undefined && alert.type === "error" && Object.keys(alert).map(key => key !== "type" && <Alert severity={alert.type}>{alert[key]}</Alert>)}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    error={alert !== undefined && alert.type === "error"}
                    helperText={alert !== undefined && alert.type === "error" && alert.username !== undefined && alert.username}
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                />
                <TextField
                    error={alert !== undefined}
                    helperText={alert !== undefined && alert.email !== undefined && alert.email}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    error={alert !== undefined}
                    helperText={alert !== undefined && alert.password1 !== undefined && alert.password1}
                    margin="normal"
                    required
                    fullWidth
                    name="password1"
                    label="Password"
                    type="password"
                    id="password1"
                />
                <TextField
                    error={alert !== undefined}
                    helperText={alert !== undefined && alert.password2 !== undefined && alert.password2}
                    margin="normal"
                    required
                    fullWidth
                    name="password2"
                    label="Confirm Password"
                    type="password"
                    id="password2"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Create Account
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link href="password_reset" variant="body2">
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="login" variant="body2">
                            Already have an account?
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}