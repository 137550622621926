import React from "react";
import axios from "axios";
import CSRFToken from "./CSRFToken";

export default function PageDetailLoader(url) {
    console.log("pagedetailloader", url);
    const res = axios.get(url)
        .catch(error => {
        console.log("get user error", error);
        })
        .then(response => {
        return {...response.data, ...{'csrf_token': CSRFToken}}
        })
        ;
    return res;
}